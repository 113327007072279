import './App.css';
import Pages from './components/pages/Pages';

function App() {
  return (
  <Pages />
  );
}

export default App;
